import './scss/index.scss';
import React, { useState, useEffect, useRef , useContext} from 'react';

import { QuestionFormContext } from '../../../context/question/QuestionFormContext';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';
import { QuestionComponent } from '../Question.component';
import { ResultListContainer, FreeResultFormContainer } from '../../result';
import { AddNewContentContainer, AiAssistantContainer } from '../../ai';
import { TaskDescriptionComponent } from '../TaskDescription.component/TaskDescription.component';

export const QuestionsCourseFormComponent = (props) => {
    const {
        task,
        inProgress,
        event,
        question,
        results,
        handleMouseUp,
        ...other
    } = props;
    const {user} = useContext(FirebaseContext);
    const questionContentRef = useRef((!!question?.question ? question?.question : '') + '\n\n');
    const [questionContent, setQuestionContent] = useState((!!question?.question ? question?.question : '') + '\n\n');
    const onChangeQuestionContent = (content) => {
        setQuestionContent((prev) => {
            const startContent = !prev ? '\n\n' : prev;
            const newContent = startContent + content;
            questionContentRef.current = newContent
            return newContent;
        });
    }
    const scrollToClasses = useRef();
    const handleScrollClick = () => {
        scrollToClasses.current.scrollIntoView();
    };
    const instructorSubscriptions = event?.userSubscriptions?.filter(subscription => subscription.role === 'INSTRUCTOR');

    useEffect(() => {
        setQuestionContent((!!question?.question ? question?.question : '') + '\n\n');
    }, [question])

    const isHideAi = event?.isHideAi;
    const showAiForStudents = !isHideAi;
    return (
        <QuestionFormContext.Provider value={{ getQuestionContent: () => questionContentRef.current }}>
            <div className='question_inner'>
                <div onMouseUp={ handleMouseUp}>
                    {(event?.tasks?.length > 20 || task?.questions?.length > 10) && (user?.id === event?.creator?.id) &&
                        <small className="error">Max 20 modules and 10 lessons allowed. Delete extra modules/lessons to generate AI content.</small>}

                    {!question ?
                        <TaskDescriptionComponent
                            {...other}
                            task={task}
                            inProgress={inProgress}
                            showAiForStudents={showAiForStudents}
                            errorNumberOfLessons={event?.tasks?.length > 20}
                            event={event}
                        />
                        : (
                            <>
                                <QuestionComponent
                                    question={question}
                                    questionContent={questionContent}
                                    task={task}
                                    inProgress={inProgress}
                                    key={question?.id}
                                    showAiForStudents={showAiForStudents}
                                    {...other}
                                />
                                {!inProgress &&
                                    <AddNewContentContainer
                                        {...other}
                                        task={task}
                                        question={question}
                                        questionContent={questionContent}
                                        handleChangeQuestionContent={onChangeQuestionContent}
                                        inProgress={inProgress}
                                        event={event}
                                    />
                                }
                            </>
                        )}
                    <FreeResultFormContainer
                        {...other}
                        id={task?.id}
                        task={task}
                    />

                </div>
                <div ref={scrollToClasses}/>

                {showAiForStudents  && (
                    <AiAssistantContainer
                        task={task}
                        inProgress={inProgress}
                        question={question}
                        handleScrollClick={handleScrollClick}
                        handleMouseUp={handleMouseUp}
                        // instructorId={(instructorSubscriptions && instructorSubscriptions[0]?.studentId )||task?.creator?.id}
                        instructorId={(instructorSubscriptions && instructorSubscriptions[0]?.studentId )}
                        {...other}
                    />
                )}

                {!!results?.length && (
                    <ResultListContainer
                        {...other}
                        taskId={task.id}
                        results={results}
                        task={task}
                        question={question}
                    />
                )}
            </div>
        </QuestionFormContext.Provider>
    );
};
