import './scss/index.scss';
import '../../common/VideoIframe/scss/mermaid.scss';

import React, {useMemo} from 'react';
import ReactMarkdown from 'react-markdown';
import katex from 'katex';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import remarkMermaidPlugin from 'remark-mermaid-plugin';

export const ReactMarkdownComponent = (props) => {
    const { children, onMouseUp, className, style, linkTarget = "_blank"} = props;

    const content = useMemo(() => {
        if (!children) {
            return children;
        }

        const mermaidRegex = /mermaid\s([\s\S]*?)\smermaid end/g;
        const mermaidOptimized = children?.replace(mermaidRegex, (match, p1) => {
            const diagramCode = p1?.trim()?.replace(/\\/g, "");
            if (diagramCode) {
                return '\n```mermaid\n' + diagramCode + '\n```\n';
            }
            return match;
        });

        const latexRegex = /\\\[[^\]]*\\\]|\((?=\s[^()]*\\)[^()]*\)/g;
        const latexOptimized = mermaidOptimized?.replace(latexRegex, (match, p1) => {
            try {
                const source = typeof p1 === "number" ? match : p1;
                const formula = source?.replace(/[\[\]]/g, '')?.replace(/\\+$/g, '')?.replace(/\\\\/g, '\\');

                return katex.renderToString(formula, {
                    output: 'mathml',
                    displayMode: false
                });
            } catch (err) {
                return match;
            }
        });
        const latexRegexSecond = /\(.*\\+.*\)/g;
        const secondLatexOptimized = latexOptimized?.replace(latexRegexSecond, (str) => {
            const reg = /\(.*?\)/g;
            const m = str?.match(reg);
            if (m?.length === 1 && m[0].includes('\\')) {
                const formula = m[0]?.replace(/\\\\/g, '\\')?.replace(/[\[\]]/g, '');
                try {
                    const html = katex.renderToString(formula, {
                        output: 'mathml',
                        displayMode: false
                    });
                    return html;
                } catch (err) {
                    return str;
                }
            } else {
                return str;
            }
        })
        return secondLatexOptimized;
    }, [children]);

    return (
        <ReactMarkdown
            children={content}
            linkTarget={linkTarget}
            escapeHtml={false}
            remarkPlugins={[[remarkMermaidPlugin, { theme: 'forest' }], remarkGfm]}
            rehypePlugins={[rehypeRaw]}
            onMouseUp={onMouseUp}
            className={className}
            style={style}
            skipHtml={true}
        />
    );
}
